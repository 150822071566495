import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import { personalInfo } from '../data/personal';
import { FaGraduationCap, FaAward, FaCode, FaLightbulb, FaChartLine, FaBrain, FaChevronDown, FaCertificate, FaDog, FaHiking, FaCoffee, FaPaw, FaLandmark, FaLeaf, FaRegLaughBeam, FaSmile, FaMusic, FaBookOpen, FaArrowRight, FaArrowLeft, FaMicrophone, FaMicrophoneAlt, FaMicrophoneAltSlash, FaExternalLinkAlt, FaEye, FaTimes, FaFileAlt, FaFilePdf } from 'react-icons/fa';
import socalRHackathonImg from '../assets/socal-r-hackathon.png';
import mockTrialImg from '../assets/mock-trial.png';
import responsibleImg from '../assets/certificates/all-of-us.pdf';
import miraImg from '../assets/doggy.jpeg';
import mira1Img from '../assets/mira.png';
import mira2Img from '../assets/mira2.jpeg';
import mira3Img from '../assets/mira3.jpeg';
import mira4Img from '../assets/mira4.jpeg';
import mira5Img from '../assets/mira5.jpeg';
import imGlad from '../assets/books/im-glad.jpg';
import night from '../assets/books/night.jpg';
import holdMeTight from '../assets/books/hold-me-tight.jpg';
import harryPotter from '../assets/books/harry-potter.jpg';
import aiPresentationImg from '../assets/presentations/IMG_2542.png';
import aiPresentationPdf from '../assets/presentations/DM-Human-Centered AI-Enhancing-Human-Capabilities-Presentation.pdf';
import aiHandoutPdf from '../assets/presentations/human-centered-ai-handout.pdf';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const [expandedAwards, setExpandedAwards] = useState(new Set());
  const [expandedEducation, setExpandedEducation] = useState(new Set());
  const [expandedPersonal, setExpandedPersonal] = useState(false);
  const [currentDogImageIndex, setCurrentDogImageIndex] = useState(0);
  const [prevDogImageIndex, setPrevDogImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const dogImages = [miraImg, mira1Img, mira2Img, mira3Img, mira4Img, mira5Img];
  const [isHoveringDogImage, setIsHoveringDogImage] = useState(false);
  const dogImageRefs = useRef([]);
  const [showPresentationPopup, setShowPresentationPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Check URL parameters on component mount to see if we should show the presentation
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const presentationParam = searchParams.get('presentation');
    
    if (presentationParam === 'human-centered-ai') {
      setShowPresentationPopup(true);
      
      // Scroll to awards section
      const awardsSection = document.getElementById('awards-section');
      if (awardsSection) {
        awardsSection.scrollIntoView({ behavior: 'smooth' });
      }
      
      // Expand the NOCCC award to show context
      setExpandedAwards(new Set([0]));
    }
  }, [location.search]);

  // Preload all dog images when component mounts
  useEffect(() => {
    // Create array of image elements to preload images
    const imagePromises = dogImages.map((src, index) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => reject();
        // Store references to image elements
        dogImageRefs.current[index] = img;
      });
    });

    // Set images as loaded when all promises resolve
    Promise.all(imagePromises)
      .then(() => setImagesLoaded(true))
      .catch(err => console.error("Error preloading images:", err));
  }, []);

  // Close popup when escape key is pressed
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape' && showPresentationPopup) {
        closePopup();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showPresentationPopup]);

  const togglePresentationPopup = () => {
    if (!showPresentationPopup) {
      // Opening the popup - update URL
      navigate('?presentation=human-centered-ai', { replace: true });
    } else {
      // Closing the popup - remove parameter from URL
      navigate(location.pathname, { replace: true });
    }
    
    setShowPresentationPopup(prev => !prev);
  };
  
  const closePopup = () => {
    // Remove parameter from URL
    navigate(location.pathname, { replace: true });
    setShowPresentationPopup(false);
  };

  const nextDogImage = () => {
    setPrevDogImageIndex(currentDogImageIndex);
    setCurrentDogImageIndex((prevIndex) => (prevIndex + 1) % dogImages.length);
  };

  const prevDogImage = () => {
    setPrevDogImageIndex(currentDogImageIndex);
    setCurrentDogImageIndex((prevIndex) => (prevIndex - 1 + dogImages.length) % dogImages.length);
  };

  const handleDogImageDragEnd = (e, info) => {
    if (info.offset.x > 50) {
      prevDogImage();
    } else if (info.offset.x < -50) {
      nextDogImage();
    }
  };

  const gradientStyles = `
    :root {
      /* Explicitly defined gradients with hex colors to ensure cross-browser consistency */
      --gradient-1: linear-gradient(to bottom, #f0f9ff, #c4b5fd);
      --gradient-2: linear-gradient(to bottom, #e0f7fa, #bfdbfe);
      --gradient-3: linear-gradient(to bottom, #f0f9ff, #c4b5fd);
      
      /* Explicitly defined colors for personal section in light mode */
      --personal-bg: #f8fafc;
      --personal-accent: #e0f2fe;
      --personal-border: #bae6fd;
      
      /* Subtle hover effects for light mode with hex colors */
      --personal-bg-hover: #f9fafb;
      --personal-accent-hover: #dbeafe;
      --personal-border-hover: #93c5fd;
    }
    
    @media (prefers-color-scheme: dark) {
      :root {
        /* Explicitly defined gradients with hex colors for dark mode */
        --gradient-1: linear-gradient(to bottom, #1e3a8a, #6d28d9);
        --gradient-2: linear-gradient(to bottom, #1e40af, #7e22ce);
        --gradient-3: linear-gradient(to bottom, #1e3a8a, #6d28d9);
        
        /* Explicitly defined colors for dark mode */
        --personal-bg: #111827;
        --personal-accent: #1e3a8a;
        --personal-border: #4f46e5;
        
        /* Hover effects for dark mode with solid colors */
        --personal-bg-hover: #1f2937;
        --personal-accent-hover: #2563eb;
        --personal-border-hover: #6366f1;
      }
    }

    .dark {
      /* Explicitly defined gradients with hex colors for dark mode */
      --gradient-1: linear-gradient(to bottom, #1e3a8a, #6d28d9);
      --gradient-2: linear-gradient(to bottom, #1e40af, #7e22ce);
      --gradient-3: linear-gradient(to bottom, #1e3a8a, #6d28d9);
      
      /* Explicitly defined colors for dark mode */
      --personal-bg: #111827;
      --personal-accent: #1e3a8a;
      --personal-border: #4f46e5;
      
      /* Hover effects for dark mode with solid colors */
      --personal-bg-hover: #1f2937;
      --personal-accent-hover: #2563eb;
      --personal-border-hover: #6366f1;
    }
    
  .personal-section {
    background-color: #ffffff !important; /* Force white in light mode */
    border: 1px solid var(--personal-border);
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }

@media (prefers-color-scheme: light) {
  .personal-section {
    background-color: #ffffff !important;
  }
}

/* Dark Mode Fix (Make Sure This Stays Correct) */
.dark .personal-section {
  background-color: var(--personal-bg) !important;
}

    
    .personal-section:hover {
      background-color: var(--personal-bg-hover);
      border-color: var(--personal-border-hover);
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
    }
    
    .personal-accent {
      background-color: var(--personal-accent);
      color: white;
      transition: background-color 0.3s ease,
    }

    @media (prefers-color-scheme: light) {
      .personal-accent {
        background-color: #dbeafe !important; /* Lighter blue */
        color: #1E40AF !important; /* Strong blue for contrast */
      }
    }

    .dark .personal-accent {
      background-color: var(--personal-accent);
      color: #93c5fd;
    }

    
    .personal-section:hover .personal-accent {
      background-color: var(--personal-accent-hover);
    }
    
    /* Text styles with explicit color definitions */
    .personal-text {
      color: #374151; /* text-gray-700 for light mode */
      font-weight: 400;
    }
    
    .dark .personal-text {
      color: #E5E7EB; /* text-gray-200 for dark mode */
    }
    
    .personal-text-bold {
      color: #111827; /* text-gray-900 for light mode */
      font-weight: 600;
    }
    
    .dark .personal-text-bold {
      color: #F9FAFB; /* text-gray-50 for dark mode */
    }
    
    /* Dog gallery styles - improved for smooth transitions and cross-browser compatibility */
    .dog-gallery-container {
      width: 100%;
      position: relative;
      margin: 0 auto;
    }
    
    .main-dog-image-container {
      position: relative;
      aspect-ratio: 1;
      width: 100%;
      max-width: 220px;
      margin: 0 auto;
      border-radius: 1rem;
      overflow: hidden;
      background-color: #f3f4f6; /* Light gray background while images load */
    }
    
    .dark .main-dog-image-container {
      background-color: #1f2937; /* Dark gray background for dark mode */
    }

    /* Create a positioning context for the images */
    .dog-images-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    
    .dog-carousel-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.85);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      opacity: 0;
      transition: all 0.3s ease;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    
    @media (max-width: 640px) {
      .dog-carousel-arrow {
        opacity: 0.8;
        width: 44px;
        height: 44px;
      }
    }
    
    .dog-carousel-container:hover .dog-carousel-arrow {
      opacity: 0.8;
    }
    
    .dog-carousel-arrow:hover {
      opacity: 1 !important;
      transform: translateY(-50%) scale(1.05);
      background-color: rgba(255, 255, 255, 0.95);
    }
    
    .dark .dog-carousel-arrow {
      background-color: rgba(30, 41, 59, 0.85);
      color: #e5e7eb;
    }
    
    .dark .dog-carousel-arrow:hover {
      background-color: rgba(30, 41, 59, 0.95);
    }
    
    .dog-carousel-arrow.left {
      left: 8px;
    }
    
    .dog-carousel-arrow.right {
      right: 8px;
    }
    
    .main-dog-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: grab;
      border-radius: 0.75rem;
    }
    
    .main-dog-image:active {
      cursor: grabbing;
    }
    
    /* Dots indicators for dog carousel */
    .carousel-dots {
      display: flex;
      justify-content: center;
      gap: 6px;
      margin-top: 12px;
    }
    
    .carousel-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #9CA3AF; /* Explicit gray color */
      opacity: 0.5;
      transition: all 0.3s ease;
    }
    
    .carousel-dot.active {
      background-color: #3B82F6; /* Explicit primary blue color */
      transform: scale(1.2);
      opacity: 1;
    }
    
    /* Interest tags styling with explicit colors */
    .interest-tag {
      display: inline-block;
      border-radius: 9999px;
      background-color: #DBEAFE; /* Keep background as light blue */
      color: #1E40AF !important; /* Dark blue text */
      font-weight: 600; /* Increased font weight */
      border: 1px solid #93C5FD; /* Light blue border */
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      transition: all 0.2s ease;
    }

    @media (prefers-color-scheme: light) {
      .interest-tag {
        color: #1E40AF !important; /* Dark blue text */
        border: 1px solid #93C5FD; /* Light blue border */
      }
    }

    .dark .interest-tag {
      color: #FFFFFF; /* Set text color to white in dark mode */
    }

    
    .interest-tag:hover {
      background-color: var(--personal-accent-hover);
      transform: translateY(-2px);
    }
    
    /* Fix for background transparency issues in Safari */
    .bg-white\/40 {
      background-color: rgba(255, 255, 255, 0.4) !important;
    }
    
    .dark .bg-white\/40 {
      background-color: rgba(255, 255, 255, 0.05) !important;
    }
    
    .dark .bg-gray-800\/40 {
      background-color: rgba(31, 41, 55, 0.4) !important;
    }
    
    /* Light mode styles for icons inside .personal-accent */
    @media (prefers-color-scheme: light) {
      .personal-accent svg {
        color: #1E40AF !important; /* Dark blue for icons */
      }
    }
  `;

  const education = [
    {
      school: "Chapman University",
      degrees: [
        {
          name: "Master of Science in Electrical Engineering and Computer Science",
          emphasis: "Intelligent Systems & Machine Learning",
          gpa: "4.0/4.0",
          date: "Anticipated: May 2026",
          description: [
            "Specializes in ubiquitous computing and systems-of-systems through advanced coursework in electrical, intelligent, and computing systems",
            "Develops skills in designing component-level solutions that cross domains and in utilizing intelligent algorithms for data analysis",
            "Builds strong foundation in ethics and leadership in preparation for modern engineering roles"
          ]
        },
        {
          name: "Master of Science in Behavioral and Computational Economics",
          emphasis: "Predictive Analytics & Decision Science",
          gpa: "3.92/4.0",
          date: "Dec 2024",
          description: [
            "Focused on application of laboratory experiments, computer simulations, and mathematical analysis to study and design efficient economic systems",
            "Engaged in hands-on projects to create and test exchange systems for applications in stock trading, online auctions, scheduling, insurance, and public utilities",
            "Excelled in curriculum that emphasized personal projects and experimentation, supported by expert faculty"
          ]
        }
      ],
      activities: [
        "Senator and GRAD Coalition Representative on Graduate Student Association Executive Board", 
        "External Vice President of Artificial Intelligence Club",
        "Beta Gamma Sigma",
        "InterPhase Incubator Entrepreneurship Program"
      ]
    },
    {
      school: "University of California, San Diego",
      degrees: [{
        name: "Bachelor of Science in Business Economics",
        emphasis: "",
        date: "Sept 2023",
        description: [
          "Completed comprehensive curriculum combining core economics disciplines with business fundamentals",
          "Trained in microeconomics, macroeconomics, econometrics, accounting, finance, management, marketing, operations, and strategy",
          "Prepared for professional world with skills to understand and influence how firms operate within markets and to extract insights from data"
        ]
      }],
      activities: ["Speech and Debate Team Member"]
    }
  ];

  const certificates = [
    {
      name: "Deep Learning Specialization",
      organization: "Coursera - deeplearning.ai",
      date: "2024",
      link: "https://www.coursera.org/account/accomplishments/verify/T22FAH6UD3ZP"
    },
    {
      name: "Google Cloud Fundamentals",
      organization: "Coursera - Google Cloud",
      date: "2023",
      link: "https://www.coursera.org/account/accomplishments/verify/T5SACE3FGJD9"
    },
    {
      name: "Python for Everybody",
      organization: "Coursera - University of Michigan",
      date: "2023",
      link: "https://www.coursera.org/account/accomplishments/verify/6BN2TAS82L79"
    },
    {
      name: "Responsible Conduct of Research All of Us Research Program",
      organization: "All of Us Research Program",
      date: "2025",
      link: responsibleImg
    }
  ];

  const awards = [
    {
      title: "North Orange County Computer Club Scholarship Award",
      date: "November 2024",
      organization: "North OC Computer Club",
      institution: "Chapman University Fowler School of Engineering",
      details: [
        "Awarded scholarship for excellence in computer science and artificial intelligence",
        "Selected to present on 'Human-Centered AI: Enhancing Human Capabilities' at NOCCC club",
        "Explored critical AI challenges including privacy concerns, misinformation, and algorithmic bias",
        "Advocated for human-centered approach to AI development and implementation"
      ],
      presentation: {
        title: "Human-Centered AI: Enhancing Human Capabilities",
        preview: aiPresentationImg,
        pdf: aiPresentationPdf,
        handout: aiHandoutPdf,
        summary: [
          "Explores how AI can enhance human capabilities when developed ethically",
          "Demonstrates AI's transformation of education, accessibility, finance, and creativity",
          "Warns of ethical risks including algorithmic bias, lack of transparency, and privacy concerns",
          "Advocates for Human-Centered AI with transparency, fairness, and inclusive governance"
        ]
      }
    },
    {
      title: "Outstanding Academic Performance - Beta Gamma Sigma",
      date: "May 2024",
      organization: "Beta Gamma Sigma",
      institution: "Chapman University Argyros College of Business & Economics",
      details: [
        "Inducted into Beta Gamma Sigma for academic excellence in business studies",
        "Ranked in top 20% of Chapman master's students at AACSB-accredited program"
      ]
    },
    {
      title: "Best Analysis Award - SoCal R Hackathon",
      date: "April 2024",
      organization: "Southern California R Users Group",
      institution: "Chapman University",
      details: [
        "Awarded 'Best Analysis' for outstanding statistical modeling and analysis",
        "Employed logistical regression to analyze US Census data relationships",
        "Recognized for effective methodology validation and clear communication of results"
      ],
      image: socalRHackathonImg
    },
    {
      title: "Full Academic Scholarship",
      date: "May 2023",
      organization: "Chapman University",
      institution: "Chapman University Argyros College of Business & Economics",
      details: [
        "Awarded full scholarship to M.S. in Behavioral and Computational Economics program",
        "Scored in top quartile on Graduate Record Exam (GRE)",
        "Selected based on academic excellence and research potential"
      ]
    },
    {
      title: "Speech and Debate - NPDA 3rd Place",
      date: "October 2021",
      organization: "El Camino Forensics",
      institution: "UC San Diego",
      details: [
        "Achieved third place in National Parliamentary Debate Association category",
        "Demonstrated excellence in rapid research and persuasive argumentation",
        "Collaborated effectively with partner in competitive debate rounds"
      ]
    },
    {
      title: "Student of the Year - U.S. Government",
      date: "May 2020",
      organization: "Phil Lopez",
      institution: "Fairmont Preparatory Academy",
      details: [
        "Earned highest grade in U.S. Government course",
        "Demonstrated exceptional understanding of political systems and policy analysis"
      ]
    },
    {
      title: "Student of the Year - Theater Rehearsal and Performance",
      date: "May 2020",
      organization: "Jeremy Cooley",
      institution: "Fairmont Preparatory Academy",
      details: [
        "Awarded for dedicated participation and skilled virtual performance in the school's online production of 'High School Musical: The Musical: Fairmont's Preparatory Academy Edition'"
      ]
    },
    {
      title: "Mock Trial - Outstanding Witness",
      date: "May 2018",
      organization: "Orange Unified School District (OUSD)",
      institution: "Villa Park High School",
      details: [
        "Awarded the Outstanding Witness for Orange Unified School District (OUSD)",
        "Demonstrated skills in articulation, argumentation, and impromptu public speaking"
      ],
      image: mockTrialImg
    }
  ];

  const interests = [
    {
      title: "Machine Learning & Human-Centered AI",
      icon: FaBrain,
      description: "Developing intelligent systems that empower humans."
    },
    {
      title: "Software Engineering",
      icon: FaCode,
      description: "Building robust and scalable applications with modern technologies."
    },
    {
      title: "Data Science",
      icon: FaChartLine,
      description: "Uncovering insights and patterns in complex datasets."
    },
    {
      title: "Innovation",
      icon: FaLightbulb,
      description: "Always exploring new technologies and creative solutions."
    }
  ];

  const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const toggleAward = (awardIndex) => {
    setExpandedAwards(prev => {
      const newSet = new Set(prev);
      if (newSet.has(awardIndex)) {
        newSet.delete(awardIndex);
      } else {
        newSet.add(awardIndex);
      }
      return newSet;
    });
  };

  const toggleEducation = (eduIndex) => {
    setExpandedEducation(prev => {
      const newSet = new Set(prev);
      if (newSet.has(eduIndex)) {
        newSet.delete(eduIndex);
      } else {
        newSet.add(eduIndex);
      }
      return newSet;
    });
  };

  const togglePersonal = () => {
    setExpandedPersonal(prev => !prev);
  };

  return (
    <section id="about" className="py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
      <style dangerouslySetInnerHTML={{ __html: gradientStyles }} />
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.2
              }
            }
          }}
          className=""
        >
          <div className="text-center mb-16">
            <motion.h2 
              variants={fadeUpVariants}
              className="text-4xl font-bold text-gray-900 dark:text-white mb-4"
            >
              About Me
            </motion.h2>
            <motion.div
              variants={fadeUpVariants}
              className="w-24 h-1 bg-primary-500 mx-auto mb-8"
            />
            <motion.p
              variants={fadeUpVariants}
              className="text-lg text-gray-600 dark:text-gray-300 max-w-xl mx-auto"
            >
              I have a passion for science and am fully immersed in the worlds of artificial intelligence, software engineering, and economics! 
              My journey combines academic endeavors and hands-on development experience.
            </motion.p>
          </div>

          <div className="max-w-3xl mx-auto space-y-16">
            <motion.div
              variants={fadeUpVariants}
              className="mt-16"
            >
              <div className="text-left mb-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <FaMusic className="text-2xl text-primary-500" />
                    <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                      Off the Clock
                    </h3>
                  </div>
                  <button 
                    onClick={togglePersonal}
                    className="p-2 rounded-full bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-300"
                    aria-label={expandedPersonal ? "Collapse personal section" : "Expand personal section"}
                  >
                    <FaChevronDown 
                      className={`text-gray-500 dark:text-gray-300 transition-transform duration-300 ${expandedPersonal ? 'rotate-180' : ''}`} 
                    />
                  </button>
                </div>
                <p className="text-gray-800 dark:text-gray-300 mt-2">
                  Beyond the professional world, here's a glimpse into who I am when I'm not at my desk :)&#8203;
                </p>
              </div>

              <AnimatePresence>
                {expandedPersonal && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="personal-section p-6 md:p-8 bg-white shadow-md rounded-lg border border-gray-200">
                      {/* Dog Carousel Section */}
                      <div className="mb-8">
                        <div className="flex items-center gap-3 mb-4">
                          <div className="p-2 personal-accent rounded-lg">
                            <FaDog className="text-lg text-white" />
                          </div>
                          <h4 className="text-lg font-semibold personal-text-bold">
                            Meet My Dog
                          </h4>
                        </div>
                        
                        <div className="flex flex-col md:flex-row gap-6 items-center">
                          {/* Dog carousel */}
                          <div className="w-full md:w-1/3">
                            <div 
                              className="dog-gallery-container dog-carousel-container"
                              onMouseEnter={() => setIsHoveringDogImage(true)}
                              onMouseLeave={() => setIsHoveringDogImage(false)}
                            >
                              <div className="main-dog-image-container">
                                <div 
                                  className="dog-carousel-arrow left"
                                  onClick={prevDogImage}
                                  aria-label="Previous image"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                                  </svg>
                                </div>
                                <div 
                                  className="dog-carousel-arrow right"
                                  onClick={nextDogImage}
                                  aria-label="Next image"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                  </svg>
                                </div>
                                
                                {/* Dog images container with absolute positioning for smooth transitions */}
                                <div className="dog-images-wrapper">
                                  {dogImages.map((src, index) => (
                                    <motion.img 
                                      key={index}
                                      src={src}
                                      alt={`My dog Mira (${index + 1} of ${dogImages.length})`}
                                      className="main-dog-image"
                                      initial={{ opacity: 0 }}
                                      animate={{ 
                                        opacity: currentDogImageIndex === index ? 1 : 0,
                                        zIndex: currentDogImageIndex === index ? 2 : 1
                                      }}
                                      transition={{ 
                                        duration: 0.5,
                                        ease: "easeInOut"
                                      }}
                                      style={{
                                        display: (index === currentDogImageIndex || index === prevDogImageIndex) ? 'block' : 'none'
                                      }}
                                      drag="x"
                                      dragConstraints={{ left: 0, right: 0 }}
                                      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                                      onDragEnd={handleDogImageDragEnd}
                                    />
                                  ))}
                                </div>
                              </div>
                              
                              <div className="carousel-dots">
                                {dogImages.map((_, index) => (
                                  <div 
                                    key={index} 
                                    className={`carousel-dot ${currentDogImageIndex === index ? 'active' : ''}`}
                                    onClick={() => {
                                      setPrevDogImageIndex(currentDogImageIndex);
                                      setCurrentDogImageIndex(index);
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                          
                          {/* Dog description */}
                          <div className="md:w-2/3">
                            <p className="text-base personal-text">
                              This is Mira, my coding companion and debugging expert. Her specialty is reminding me to take breaks by strategically placing tennis balls on my keyboard when I've been working too long. She's also an avid hiker who carries her own backpack on our trail adventures!
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Personal Info Section */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                        {/* What I Do When I'm Not Coding */}
                        <div>
                          <div className="flex items-center gap-3 mb-4">
                            <div className="p-2 personal-accent rounded-lg">
                              <FaHiking className="text-lg text-white" />
                            </div>
                            <h4 className="text-lg font-semibold personal-text-bold">
                              When I'm Not Coding
                            </h4>
                          </div>
                          
                          <div className="flex flex-wrap gap-2 mb-4">
                            <span className="interest-tag personal-text">🏋️‍♀️ Fitness</span>
                            <span className="interest-tag personal-text">🎤 Singing</span>
                            <span className="interest-tag personal-text">✍️ Writing</span>
                            <span className="interest-tag personal-text">📖 Reading</span>
                            <span className="interest-tag personal-text">⛰️ Hiking</span>
                          </div>
                          
                          <ul className="space-y-3 personal-text">
                            <li className="flex items-start gap-3">
                              <span className="w-1.5 h-1.5 rounded-full bg-primary-500 dark:bg-primary-400 mt-1.5 flex-shrink-0"></span>
                              <span>Experimenting with new workouts, especially anything involving the gym!</span>
                            </li>
                            <li className="flex items-start gap-3">
                              <span className="w-1.5 h-1.5 rounded-full bg-primary-500 dark:bg-primary-400 mt-1.5 flex-shrink-0"></span>
                              <span>Singing - Sabrina Carpenter is my favorite!!</span>
                            </li>
                            <li className="flex items-start gap-3">
                              <span className="w-1.5 h-1.5 rounded-full bg-primary-500 dark:bg-primary-400 mt-1.5 flex-shrink-0"></span>
                              <span>Writing, whether it's poetry, my autobiography, or scientific papers 🧬</span>
                            </li>
                            <li className="flex items-start gap-3">
                              <span className="w-1.5 h-1.5 rounded-full bg-primary-500 dark:bg-primary-400 mt-1.5 flex-shrink-0"></span>
                              <span>
                                I love diving into a good book, whether it's fiction, autobiographies, or self-help books. 
                                Book recommendations: <Link to="/books" className="text-primary-500 hover:underline">Check them out!</Link>
                              </span>
                            </li>
                            <li className="flex items-start gap-3">
                              <span className="w-1.5 h-1.5 rounded-full bg-primary-500 dark:bg-primary-400 mt-1.5 flex-shrink-0"></span>
                              <span>Exploring nearby trails with my dog!</span>
                            </li>
                          </ul>
                        </div>

                        {/* Personal Motto */}
                        <div>
                          <div className="flex items-center gap-3 mb-4">
                            <div className="p-2 personal-accent rounded-lg">
                              <FaCoffee className="text-lg text-white" />
                            </div>
                            <h4 className="text-lg font-semibold personal-text-bold">
                              Personal Motto
                            </h4>
                          </div>
                          
                          <div className="bg-white/40 dark:bg-gray-800/40 p-5 rounded-lg">
                            <p className="text-center italic text-lg font-medium personal-text-bold mb-3">
                              "Always stay curious!"
                            </p>
                            <div className="w-16 h-1 bg-primary-500/60 dark:bg-primary-400/60 mx-auto mb-4 rounded-full" />
                            <p className="text-center personal-text">
                            Curiosity has helped me tackle tough homework, writer's block, and even the trickiest coding problems... Every challenge is just a question waiting for an answer!
                            </p>
                          </div>
                        </div>
                      </div>
                      
                      {/* Book Recommendations Section */}
                      <div className="mt-8">
                        <div className="flex items-center gap-3 mb-4">
                          <div className="p-2 personal-accent rounded-lg">
                            <FaBookOpen className="text-lg text-white" />
                          </div>
                          <h4 className="text-lg font-semibold personal-text-bold">
                            My Book Recommendations
                          </h4>
                        </div>
                        
                        <div className="bg-white/40 dark:bg-gray-800/40 p-5 rounded-lg">
                          <div className="flex flex-col md:flex-row items-center gap-4">
                            <div className="flex-shrink-0">
                              <div className="grid grid-cols-2 gap-2 w-40">
                                <img src={imGlad} alt="I'm Glad My Mom Died" className="w-full h-auto rounded-md shadow-sm" />
                                <img src={night} alt="Night" className="w-full h-auto rounded-md shadow-sm" />
                                <img src={holdMeTight} alt="Hold Me Tight" className="w-full h-auto rounded-md shadow-sm" />
                                <img src={harryPotter} alt="Harry Potter" className="w-full h-auto rounded-md shadow-sm" />
                              </div>
                            </div>
                            <div className="flex-grow">
                              <p className="personal-text mb-4">
                                From memoirs that moved me to fiction that fired my imagination, I love a wide range of books. 
                                I've curated recommendations across genres like Memoir, Fiction, Self-Improvement, Philosophy, 
                                and Relationships.
                              </p>
                              <div className="flex justify-center md:justify-start">
                                <Link 
                                  to="/books" 
                                  className="flex items-center gap-2 px-5 py-2.5 bg-primary-500 text-white rounded-full hover:bg-primary-600 transition-colors duration-300 shadow-sm"
                                >
                                  <span>Browse my bookshelf</span>
                                  <FaArrowRight className="text-sm" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Songs Section */}
                      <div className="mt-8">
                        <div className="flex items-center gap-3 mb-4">
                          <div className="p-2 personal-accent rounded-lg">
                            <FaMusic className="text-lg text-white" />
                          </div>
                          <h4 className="text-lg font-semibold personal-text-bold">
                            My Favorite Songs to Belt in the Car!
                          </h4>
                        </div>
                        <iframe 
                          style={{ borderRadius: '12px' }} 
                          src="https://open.spotify.com/embed/playlist/7IQbW9q2But1zOpynlExcx?utm_source=generator" 
                          width="100%" 
                          height="152" 
                          frameBorder="0" 
                          allowFullScreen 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

              {/* Move the button here */}
              <motion.div
                variants={fadeUpVariants}
                className="mt-4 flex justify-center"
              >
                <motion.a
                  href="/mira/index.html"
                  className="text-lg font-bold text-white bg-gradient-to-r from-pink-400 to-purple-500 rounded-full inline-flex items-center gap-1.5 px-4 py-2 shadow-md hover:scale-105 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span>Want a smile? Meet Mira!</span>
                  <FaPaw className="text-lg" />
                </motion.a>
              </motion.div>

              {/* Collapsed view hint */}
              {!expandedPersonal && (
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="text-center mt-2"
                >
                  <button 
                    onClick={togglePersonal}
                    className="text-sm text-gray-400 dark:text-gray-500 italic"
                  >
                    Click to learn a bit more about me outside of work!
                  </button>
                </motion.div>
              )}
            </motion.div>

            <motion.div
              variants={fadeUpVariants}
            >
              <div className="text-left mb-8">
                <div className="flex items-center gap-3">
                  <FaGraduationCap className="text-3xl text-primary-500" />
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                    Education
                  </h3>
                </div>
              </div>
              <div className="space-y-6">
                {education.map((edu, index) => (
                  <motion.div
                    key={index}
                    variants={fadeUpVariants}
                    className="bg-gray-50 dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                  >
                    <div>
                      <h4 className="text-xl font-bold text-gray-900 dark:text-white mb-6">
                        {edu.school}
                      </h4>
                      <div className="space-y-8">
                        {edu.degrees.map((degree, i) => (
                          <div key={i} className="border-b border-gray-200 dark:border-gray-700 pb-6 last:border-b-0 last:pb-0">
                            <div className="flex flex-col md:flex-row md:items-start md:justify-between mb-2">
                              <div className="flex-grow">
                                <p className="text-lg font-semibold text-primary-600 dark:text-primary-400">
                                  {degree.name}
                                </p>
                                {degree.emphasis && (
                                  <p className="text-sm italic text-gray-600 dark:text-gray-300 mt-0.5">
                                    Emphasis: {degree.emphasis}
                                  </p>
                                )}
                              </div>
                              <div className="text-right mt-2 md:mt-0">
                                {degree.gpa && (
                                  <p className="text-sm text-gray-600 dark:text-gray-300">
                                    GPA: {degree.gpa}
                                  </p>
                                )}
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                  {degree.date}
                                </p>
                              </div>
                            </div>
                            
                            {degree.description && (
                              <div className="mt-3">
                                <button
                                  onClick={() => toggleEducation(`${edu.school}-${i}`)}
                                  className="flex items-center gap-1 text-sm text-primary-500 hover:text-primary-600 transition-colors"
                                >
                                  <span>View details</span>
                                  <motion.div
                                    animate={{ rotate: expandedEducation.has(`${edu.school}-${i}`) ? 180 : 0 }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    <FaChevronDown className="w-3 h-3" />
                                  </motion.div>
                                </button>
                                
                                <AnimatePresence>
                                  {expandedEducation.has(`${edu.school}-${i}`) && (
                                    <motion.div
                                      initial={{ height: 0, opacity: 0 }}
                                      animate={{ height: "auto", opacity: 1 }}
                                      exit={{ height: 0, opacity: 0 }}
                                      transition={{ duration: 0.2 }}
                                      className="overflow-hidden mt-2 w-full"
                                    >
                                      <div className="space-y-2">
                                        {degree.description.map((detail, idx) => (
                                          <div key={idx} className="flex items-start gap-2">
                                            <div className="w-1.5 h-1.5 rounded-full bg-primary-500 mt-2 flex-shrink-0" />
                                            <span className="text-sm text-gray-600 dark:text-gray-300">
                                              {detail}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      
                      {edu.activities && edu.activities.length > 0 && (
                        <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
                          <div className="flex items-center gap-2 mb-3">
                            <FaLightbulb className="text-primary-500" />
                            <p className="font-semibold text-gray-700 dark:text-gray-300">
                              Activities
                            </p>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-gray-600 dark:text-gray-400">
                            {edu.activities.map((activity, i) => (
                              <div key={i} className="flex items-start gap-2">
                                <div className="w-1.5 h-1.5 rounded-full bg-primary-500 flex-shrink-0 mt-1.5" />
                                <span className="text-sm">{activity}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            <motion.div
              variants={fadeUpVariants}
            >
              <div className="text-left mb-8">
                <div className="flex items-center gap-3">
                  <FaCertificate className="text-3xl text-primary-500" />
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                    Certificates
                  </h3>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {certificates.map((cert, i) => (
                  <div key={i} className="flex items-start gap-3 p-4 bg-white dark:bg-gray-700 rounded-lg shadow-sm hover:shadow transition-all duration-300">
                    <div className="p-2 bg-primary-50 dark:bg-primary-900/30 rounded-lg">
                      <FaCertificate className="text-lg text-primary-500" />
                    </div>
                    <div>
                      <h4 className="text-base font-semibold text-gray-900 dark:text-white">
                        {cert.name}
                      </h4>
                      <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                        {cert.organization} • {cert.date}
                      </p>
                      {cert.link && (
                        <a 
                          href={cert.link} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-xs text-primary-500 hover:text-primary-600 transition-colors mt-1.5 inline-block"
                        >
                          View Certificate
                        </a>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              variants={fadeUpVariants}
            >
              <div className="text-left mb-8">
                <div className="flex items-center gap-3">
                  <FaAward className="text-3xl text-primary-500" />
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white" id="awards-section">
                    Awards & Recognition
                  </h3>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-4">
                  {awards.filter((_, i) => i % 2 === 0).map((award, index) => (
                    <motion.div
                      key={index * 2}
                      variants={fadeUpVariants}
                      className="bg-gray-50 dark:bg-gray-800 rounded-xl shadow-soft hover:shadow-md transition-all duration-300"
                    >
                      <div className="p-4">
                        <div className="flex justify-between items-start">
                          <h4 className="text-base font-semibold text-gray-900 dark:text-white pr-2">
                            {award.title}
                          </h4>
                          <button
                            onClick={() => toggleAward(index * 2)}
                            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors flex-shrink-0"
                          >
                            <motion.div
                              animate={{ rotate: expandedAwards.has(index * 2) ? 180 : 0 }}
                              transition={{ duration: 0.2 }}
                            >
                              <FaChevronDown className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                            </motion.div>
                          </button>
                        </div>
                        <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400 mt-1">
                          <span>{award.organization}</span>
                          <span>{award.date}</span>
                        </div>
                      </div>

                      <AnimatePresence>
                        {expandedAwards.has(index * 2) && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="overflow-hidden border-t border-gray-100 dark:border-gray-700"
                          >
                            <div className="p-4 space-y-2">
                              {award.institution && (
                                <p className="text-sm text-gray-500 dark:text-gray-500 italic mb-2">
                                  {award.institution}
                                </p>
                              )}
                              {award.image && (
                                <img src={award.image} alt={award.title} className="w-full h-auto rounded-lg mb-2" />
                              )}
                              {award.details.map((detail, i) => (
                                <div key={i} className="flex items-start gap-2">
                                  <div className="w-1.5 h-1.5 rounded-full bg-primary-500 mt-2 flex-shrink-0" />
                                  <span className="text-sm text-gray-600 dark:text-gray-300">
                                    {detail}
                                  </span>
                                </div>
                              ))}
                              {award.presentation && (
                                <div className="mt-4 pt-3 border-t border-gray-100 dark:border-gray-700">
                                  <button
                                    onClick={togglePresentationPopup}
                                    className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors duration-300 shadow-sm mt-2 text-sm"
                                  >
                                    <FaEye className="text-xs" />
                                    <span>View Presentation</span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  ))}
                </div>

                <div className="space-y-4">
                  {awards.filter((_, i) => i % 2 === 1).map((award, index) => (
                    <motion.div
                      key={index * 2 + 1}
                      variants={fadeUpVariants}
                      className="bg-gray-50 dark:bg-gray-800 rounded-xl shadow-soft hover:shadow-md transition-all duration-300"
                    >
                      <div className="p-4">
                        <div className="flex justify-between items-start">
                          <h4 className="text-base font-semibold text-gray-900 dark:text-white pr-2">
                            {award.title}
                          </h4>
                          <button
                            onClick={() => toggleAward(index * 2 + 1)}
                            className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors flex-shrink-0"
                          >
                            <motion.div
                              animate={{ rotate: expandedAwards.has(index * 2 + 1) ? 180 : 0 }}
                              transition={{ duration: 0.2 }}
                            >
                              <FaChevronDown className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                            </motion.div>
                          </button>
                        </div>
                        <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400 mt-1">
                          <span>{award.organization}</span>
                          <span>{award.date}</span>
                        </div>
                      </div>

                      <AnimatePresence>
                        {expandedAwards.has(index * 2 + 1) && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="overflow-hidden border-t border-gray-100 dark:border-gray-700"
                          >
                            <div className="p-4 space-y-2">
                              {award.institution && (
                                <p className="text-sm text-gray-500 dark:text-gray-500 italic mb-2">
                                  {award.institution}
                                </p>
                              )}
                              {award.image && (
                                <img src={award.image} alt={award.title} className="w-full h-auto rounded-lg mb-2" />
                              )}
                              {award.details.map((detail, i) => (
                                <div key={i} className="flex items-start gap-2">
                                  <div className="w-1.5 h-1.5 rounded-full bg-primary-500 mt-2 flex-shrink-0" />
                                  <span className="text-sm text-gray-600 dark:text-gray-300">
                                    {detail}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>

            <motion.div
              variants={fadeUpVariants}
            >
              <div className="text-left mb-8">
                <div className="flex items-center gap-3">
                  <FaLightbulb className="text-2xl text-primary-500" />
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                    Focus Areas
                  </h3>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {interests.map((interest, index) => (
                  <motion.div
                    key={index}
                    variants={fadeUpVariants}
                    className="group flex items-start gap-4 p-5 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1 border border-gray-100 dark:border-gray-700"
                  >
                    <div className="p-3 bg-gradient-to-br from-primary-100 to-primary-200 dark:from-primary-900 dark:to-primary-800 rounded-lg group-hover:scale-110 transition-transform duration-300 flex-shrink-0">
                      <interest.icon className="text-xl text-primary-500 dark:text-primary-400" />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                        {interest.title}
                      </h4>
                      <p className="text-gray-600 dark:text-gray-300 text-sm leading-relaxed">
                        {interest.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>

      {/* Presentation Popup */}
      <AnimatePresence>
        {showPresentationPopup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/70"
            onClick={togglePresentationPopup}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", damping: 25 }}
              className="bg-white dark:bg-gray-800 rounded-xl max-w-3xl w-full overflow-hidden shadow-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center border-b border-gray-200 dark:border-gray-700 p-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {awards[0].presentation.title}
                </h3>
                <button 
                  onClick={togglePresentationPopup}
                  className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                >
                  <FaTimes className="text-gray-500 dark:text-gray-400" />
                </button>
              </div>
              
              <div className="p-4">
                <div className="flex flex-col md:flex-row gap-6">
                  <div className="md:w-1/2">
                    <img 
                      src={awards[0].presentation.preview} 
                      alt={awards[0].presentation.title} 
                      className="w-full h-auto rounded-lg shadow-md mb-4" 
                    />
                    
                    <div className="flex flex-col space-y-2">
                      <a 
                        href={awards[0].presentation.pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-3 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors duration-300 text-center justify-center"
                      >
                        <FaFilePdf className="text-sm" />
                        <span>View Presentation PDF</span>
                      </a>
                      
                      <a 
                        href={awards[0].presentation.handout}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-3 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-300 text-center justify-center"
                      >
                        <FaFileAlt className="text-sm" />
                        <span>View Presentation Handout</span>
                      </a>
                      
                      <div className="mt-2 pt-2 border-t border-gray-100 dark:border-gray-700">
                        <p className="text-xs text-gray-500 dark:text-gray-400 mb-2">
                          Direct link to share this presentation:
                        </p>
                        <div className="flex items-center gap-2 bg-gray-50 dark:bg-gray-700/50 p-2 rounded-md text-xs text-gray-600 dark:text-gray-300 overflow-x-auto">
                          <code>devynmiller.com/#/?presentation=human-centered-ai</code>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="md:w-1/2">
                    <h4 className="text-base font-semibold text-gray-800 dark:text-gray-200 mb-3">
                      Presentation Summary
                    </h4>
                    <ul className="space-y-2 mb-4">
                      {awards[0].presentation.summary.map((point, idx) => (
                        <li key={idx} className="flex items-start gap-2 text-sm text-gray-600 dark:text-gray-300">
                          <div className="w-1.5 h-1.5 rounded-full bg-primary-500 dark:bg-primary-400 mt-1.5 flex-shrink-0" />
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                    
                    <div className="pt-3 border-t border-gray-200 dark:border-gray-700">
                      <p className="text-xs text-gray-500 dark:text-gray-400 mb-2 italic">
                        This presentation was delivered at Chapman University for the North Orange County Computer Club, on April 6, 2025.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default About;
